
.navigation,
.navigation ul,
.navigation ul li,
.navigation ul li a,
.navigation #menu-button {
    margin: 0;
    padding: 0;
    border: 0;
    list-style: none;
    line-height: 1;
    display: inline-block;
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 2px;
}
@media (max-width:1265px){
    #utilnav{
        padding-right:0px !important;
    }
    #utilnav .navitems li a{
        padding:13px 5px !important;
        font-size:11px !imp;
    }
    .navitems {
        padding-right: 0px !important;
    }
    .accessibility {
        padding-left: 15px !important;
    }
}
    .navigation:after,
    .navigation > ul:after {
        content: ".";
        display: block;
        clear: both;
        visibility: hidden;
        line-height: 0;
        height: 0;
    }

    .navigation #menu-button {
        display: none;
    }

.navigation {
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    padding-top: 0;
    width: 100%;
    display: block;
    font-size: 12px;
    position: static;
}

nav ul {
    float: right;
}

#mainnav {
    background-repeat: repeat-x;
    top:38px;
    position: fixed;
    background-color: #DED9d2;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
}


.header.sticky #mainnav {
    top:38px;
    position: fixed
}

#mainnav ul {
    padding-right: 90px;
}

#mainnav ul ul {
    padding: 0 !important;
    background-color: #fff;
}

#mainnav a {
    z-index: 1000;
}

#mainnav ul li a {
    font-size: 17px;
}

#mainnav ul li ul li a {
    font-size: 12px;
    color: #000;
}

#utilnav {
    background-color: #ded9d2;
    padding-right: 90px;
    margin: 0 !important;
    z-index: 2031 !important;
    position: fixed;
    top:0;
}

#utilnav.sticky {
    z-index: 1000;
}

#utilnav.sticky ul li ul {
    z-index: 1000;
}

#utilnav ul li ul {
    background-color: #fff;
}

#utilnav ul li ul a {
    color: #000;
    font-size: 12px;
    font-weight: 400 !important;
}

#utilnav ul li ul a:hover {
    color: #000;
}

#utilnav a {
    font-weight: 100 !important;
}

.nav-dropdown li a {
    font-size: 24px;
}

.nav-dropdown, .nav-dropdown li, .nav-dropdown li a,.nav-sub-dropdown, .nav-sub-dropdown li, .nav-sub-dropdown li a{
    width: 185px !important;
}
.nav-sub-dropdown{
    margin-left: -40px !important;
    z-index: 3000;
}


    .navigation ul li .nav-dropdown {
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        -ms-transition: all 0.4s ease;
        -o-transition: all 0.4s ease;
        transition: all 0.4s ease;
    }

    .navigation > ul > li {
        float: left;
    }

    .navigation.align-center > ul {
        font-size: 0;
        text-align: center;
    }

        .navigation.align-center > ul > li {
            display: inline-block;
            float: none;
        }

    .navigation.align-center ul ul {
        text-align: left;
    }

    .navigation.align-right > ul > li {
        float: right;
    }

    .navigation.align-right ul ul {
        text-align: right;
    }

    .navigation > ul > li > a {
        padding: 13px 16px;
        font-size: 12px;
        font-family: 'Raleway', sans-serif;
        font-weight: 600;
        letter-spacing: 2px;
        text-decoration: none;
        text-transform: uppercase;
        color: #002b5c;
        transition: all 0.25s ease 0s;
        -webkit-transition: all 0.25s ease 0s;
        -moz-transition: all 0.25s ease 0s;
        -o-transition: all 0.25s ease 0s;
        outline:none;
    }

    .navigation > ul > li:hover{
        background-color: #2B4670
    }

   /*.header.sticky .navigation > ul > li {
        background-color: transparent;
   }*/

.header.sticky .navigation ul li a {
    /*padding: 13px 16px;
    margin: 20px 0;*/
}

.header.sticky .navigation ul ul li a, .header .navigation ul ul li a, .navigation ul ul li a {
    padding: 10px 20px;
    color:#ddd;
    letter-spacing: 1px;
    font-weight:500;
    font-size:12px;
    outline:none;
}


.navigation ul li:hover a, .header.sticky .navigation ul ul > li:hover > a, .header.sticky .navigation ul > li:hover > a,
.navigation > ul > li.active > a {
    color: #fff;
}
.navigation > ul > li.nav-has-sub > a {
  /*padding-right: 25px !important;*/
}
.navigation > ul > li.nav-has-sub > a::after {

}
.navigation > ul > li.nav-has-sub:hover > a::after {

}

.navigation ul ul {
    position: absolute;
    opacity: 0;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    top: 130%;
    visibility: hidden;
}

.navigation li:hover > ul {
    left: 0;
    opacity: 1;
    top: 100%;
    visibility: visible;
}

.navigation li:hover ul.nav-sub-dropdown {
    top: 0;
}

.navigation.align-right li:hover > ul {
    right: 0;
}

.navigation ul ul ul {
    margin-right: 100% !important;
    top: 0;
    left: 225px !important;
}

.navigation .nav-dropdown, .navigation .nav-sub-dropdown {
    padding-top: 10px;
    padding-bottom: 10px;
    background:rgba(51, 51, 102, 0.9);
}

.navigation li:hover ul.nav-sub-dropdown {
    top: -10px;
}

.navigation.align-right ul ul ul {
    margin-left: 0;
    margin-right: 100%;
}
/*.navigation ul ul li {
  height: 0;
  -webkit-transition: height .2s ease;
  -moz-transition: height .2s ease;
  -ms-transition: height .2s ease;
  -o-transition: height .2s ease;
  transition: height .2s ease;
}*/
/*.navigation ul li:hover > ul > li {
  height: 32px;
}*/
.navigation ul ul li a {
    padding: 10px 20px;
    width: 225px;
    font-size: 12px;
    text-decoration: none;
    color: #fff;
    -webkit-transition: color .2s ease;
    -moz-transition: color .2s ease;
    -ms-transition: color .2s ease;
    -o-transition: color .2s ease;
    transition: color .2s ease;
}

    .navigation ul ul li:hover > a,
    .navigation ul ul li a:hover {
        color: #083764 !important;
    }

/*.navigation ul ul li.nav-has-sub > a::after {
    position: absolute;
    top: 13px;
    right: 15px;
    width: 5px;
    height: 5px;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    content: "";
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: border-color 0.2s ease;
    -moz-transition: border-color 0.2s ease;
    -ms-transition: border-color 0.2s ease;
    -o-transition: border-color 0.2s ease;
    transition: border-color 0.2s ease;
}*/
/*.navigation ul ul li.nav-has-sub > a::after {
    position: absolute;
    top: 13px;
    right: 15px;
    width: 5px;
    height: 5px;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    content: "";
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition: border-color 0.2s ease;
    -moz-transition: border-color 0.2s ease;
    -ms-transition: border-color 0.2s ease;
    -o-transition: border-color 0.2s ease;
    transition: border-color 0.2s ease;
}*/

#mainnav ul ul ul {
    left: -145px !important;
}

.arrow::before {
    position: absolute;
    top: 13px;
    left: 7px;
    width: 5px;
    height: 5px;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    content: "";
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
    -webkit-transition: border-color .2s ease;
    -moz-transition: border-color .2s ease;
    -ms-transition: border-color .2s ease;
    -o-transition: border-color .2s ease;
    transition: border-color .2s ease;
}

.navigation.align-right ul ul li.nav-has-sub > a::after {
    right: auto;
    left: 10px;
    border-bottom: 0;
    border-right: 0;
    border-top: 1px solid #000;
    border-left: 1px solid #000;
}

.navigation ul ul li.nav-has-sub:hover > a::after {
    border-color: #000;
}


li.search {
    display:inline-block !important;
    position:static !important;
    height: 100% !important;
}
li.search:hover {
    background-color: transparent !important;
}
li.search a {
    width:28px !important;
}
li.search form {
    position: absolute;
    z-index: 100;
    width:100%;
    height:100%;
    right:0;
    margin-right: 100px;
    display: none;
}
li.search form .submit {
    position: absolute;
    height:100%;
    line-height: 100%;
    font-size:35px;
    width:80px;
    padding:0;
    z-index: 100;
}
li.search form .submit i, li.search form .exit i {
    position: relative;
    margin: 0 !important;
    color: #fff;
}

li.search form .submit, li.search form .exit {
    background-color: transparent;
}
li.search form input {
    width:100%;
    outline:none;
    border:none;
    padding:0 90px;
    font-size:24px;
    position: absolute;
    margin:0;
    top:0;
    left:0;
    height: 100%;
    background-color: transparent;
    color: #fff !important;
}
li.search form .exit {
    position: absolute;
    height:100%;
    line-height: 100%;
    font-size:40px;
    width:80px;
    padding:0;
    right:0;
    top:0;
}
.vishide {
    -webkit-transition: all 4s ease-out 0s;
  -moz-transition: all 4s ease-out 0s;
  -ms-transition: all 4s ease-out 0s;
  -o-transition: all 4s ease-out 0s;
  transition: all 4s ease-out 0s;
}
.btn-md-acs {
    padding: 3px 3px !important;
    display: inline-block;
    border: 1px solid #fff !important;
}
.btn-md-acs:hover {
    background-color: #fff !important;
    color: #083764 !important;
}
li.search #search {
    padding-right: 40px !important;
}