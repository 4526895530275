/*----------------------------------------------------
MWI CSS
----------------------------------------------------*/

/* ------------------------------------------------------------------------------------------
Reset Styles 
------------------------------------------------------------------------------------------ */

/* Resets */
section,nav,hgroup,header,footer,figure,dialog,aside,article,td,th,tr,thead,tfoot,tbody,caption,table,legend,label,form,fieldset,li,ul,ol,dd,dt,dl,q,img,em,dfn,del,code,address,acronym,abbr,a,pre,blockquote,p,h6,h5,h4,h3,h2,h1,iframe,object,span,div,body,html{margin:0;padding:0;border:0;vertical-align:baseline}section,nav,hgroup,header,footer,figure,dialog,aside,article{display:block}table{border-collapse:collapse;border-spacing:0}td,th,caption{text-align:left;font-weight:normal}th,td,table{vertical-align:middle}q:after,q:before,blockquote:after,blockquote:before{content:""}q,blockquote{quotes:"" ""}a img{border:none}


/* ------------------------------------------------------------------------------------------
Base Framework
------------------------------------------------------------------------------------------ */
.left       				{float: left;}
.right      				{float: right;}
.left-img      				{float: left;margin:5px 15px 10px 0;}
.right-img     				{float: right;margin:5px 0 10px 15px;}
.no-marg    				{margin: 0px !important;}
.top-marg					{margin-top: 40px !important;}
.center 					{text-align: center !important;}
strong, .strong 			{font-weight: bold;}
strong em, .strong em 		{font-weight: 800;}
em, .em 					{font-style: italic;}
.small 						{font-size: 12px !important; line-height: 20px;}
.wrap						{float: left; width: 100%;}
/*img 						{max-height: 100%; max-width: 100%;}*/
.no-bull 					{list-style: none;}
.scrollX						{overflow-X: auto;}
span.gc-cs-link				{color:#fff !important; text-decoration:none !important;}

body {
	position: relative;
	right:0;
	-webkit-transition:right 200ms;
}

body.active {
	left: -250px !important;
}


/* ------------------------------------------------------------------------------------------
Typography styles 
------------------------------------------------------------------------------------------ */

/* Base Text
--------------------------------------------- */
p,ul,ol,li {

}
p {

}
ul,ol {

}
a {

}
/* Headings
--------------------------------------------- */
h1 {

}
h2 {

}
h3 {

}
h4 {

}

/* Buttons
--------------------------------------------- */
.btn-blue {
	background-color: #083764;
	color: white;
}


/* ------------------------------------------------------------------------------------------
Fluid Grid Elements
------------------------------------------------------------------------------------------ */
.container {
	clear: both;
	display: block;
	margin: 0px auto;
	width: 92%;
	max-width: 1160px;
	position: relative;
}
.squeeze {
	max-width:960px;
}
.col1	{ width: 100%; float: left; }
.col2	{ width:48%;}
.col3	{ width:30.66%; }
.col2-3	{ width:65.33%; }
.col4	{ width:22%; }
.col3-4	{ width:74%; }
.col5	{ width:16.8%; }
.col2-5	{ width:37.6%; }
.col3-5	{ width:58.4%; }
.col4-5	{ width:67.2%; }
.col6	{ width:13.33%; }
.col5-6	{ width:82.67%; }
.col2,.col3,.col2-3,.col3-4,.col4,.col5,.col2-5,.col3-5,.col4-5,.col6,.col5-6{
	position:relative; margin-right:4% !important; float:left;
}
.last{
	margin-right:0 !important;
}
.clearfix:after {
	visibility: hidden;
	display: block;
	content: "";
	clear: both;
	height: 0px;
}

.col2 li {
	list-style: none;
}

.col2.full {
    width: 50% !important;
    margin: 0 !important;
    list-style: none !important;
}

.col2.full a {
	padding: 12px 0 !important;
	text-align: center;
}

/* ------------------------------------------------------------------------------------------
Forms
------------------------------------------------------------------------------------------ */
input, textarea {
	padding:0 12px;
	height:38px;
	line-height:38px;
	border: 1px solid #ccc;
	/*-webkit-box-shadow: inset 0 1px 3px #dfdfdf;*/
	outline-color: #f0433f;
	margin: 10px 0;
}
textarea {	
	height: 120px;
}

/* ------------------------------------------------------------------------------------------
Responsive Table Elements
------------------------------------------------------------------------------------------ */
.mwi-table {
	display: table;
	width: 100%;
	position: relative;
	margin-bottom:30px;
}
.mwi-table ul {
	display: table-row;
}
.mwi-table ul li {
	display: table-cell;
	padding: 5px 8px;
	font-size: 14px;
}
.mwi-table .th {
	background-color: #eee;
}
.mwi-table .tr li {
	border-bottom: 1px dashed #ddd;
}
.mwi-table .tr li span {
	display: none;
}
.mwi-table .tf {
	background-color: #eee;
	position: relative;
	text-align: right;
	color: #233046;
}
.mwi-table .tf li {
	font-weight: 600;
}
.mwi-table .tf span {
	background-color: #fff;
	padding: 4px 4px 4px 30px;
	color: #9cc563;
}
.mwi-table .title {
	background-color: #aaa;
	color: #fff;
	text-align: center;
}
@media (max-width:660px) {
	.mwi-table, .mwi-table ul, .mwi-table ul li {
		display: block;
		margin: 0 !important;
	}
	.mwi-table {
		margin-bottom: 30px !important;
	}
	.mwi-table ul {
		margin-bottom: 10px !important;
	}
	.mwi-table ul li {
		display: block;
	}
	.mwi-table .title {
		height: 30px;
		text-align: center;
		border-bottom: 1px solid #fff;
		width: 100%;
	}
	.mwi-table .title li {
		display: inline-block;
		float: left;
	}
	.mwi-table .title li:last-child {
		float: right;
	}
	.mwi-table .th {
		display: none;
	}
	.mwi-table .tr {
		border-bottom: 2px solid #aaa;
	}
	.mwi-table .tr:last-child {
		border: none;
	}
	.mwi-table .tr li {
		text-align: right !important;
		position: relative;
		padding-left: 50%;
		border-top: 1px dashed #ddd;
		border-bottom: none;
	}
	.mwi-table .tr li span {
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		background-color: #888;
		color: #fff;
		line-height: 30px;
		height: 100%;
		width: 40%;
		padding-right: 10px;
	}
	.mwi-table .tf li {
		padding: 0;
	}
	.mwi-table .tf li:last-child {
		padding: 10px;
	}
}

/* ------------------------------------------------------------------------------------------
Responsive Table HTML
------------------------------------------------------------------------------------------ *
<div class="mwi-table">
	<ul class="th">
		<li>First</li>
		<li>Last</li>
	</ul>
	<ul class="tr">
		<li>Ryan</li>
		<li>Doss</li>
	</ul>
	<ul class="tf">
		<li class="span-all">Footer Content</li>
	</ul>
</div>


/* ------------------------------------------------------------------------------------------
Navigation
------------------------------------------------------------------------------------------ */
nav ul {
	list-style: none;
}

/* Utility Nav
--------------------------------------------- */



/* Main Nav
--------------------------------------------- */
#mainnav {
	position: static;
}
.navitems li {
	transition: 0.3s;
}
.search form {
	display: block !important;
	opacity: 0;
	transition: 0.3s;
}
.search #searchButton {
	height: auto !important;
}
.search #searchButton i {
	color: #002b5c !important;
}
.search #siteSearch {
	height: auto !important;
	color: #002b5c !important;
}
.search #searchExit {
	height: auto !important;
}
.search #searchExit i {
	color: #002b5c !important;
}
/* Mobile Nav
--------------------------------------------- */
#mobile {
	background: -webkit-linear-gradient(bottom left, rgba(18, 33, 53,0.95), rgba(37, 71, 116,0.96)), url(/document-uploads/knitting.jpeg);
	background: -o-linear-gradient(bottom left, rgba(18, 33, 53,0.95), rgba(37, 71, 116,0.96)), url(/document-uploads/knitting.jpeg);
	background: linear-gradient(to top right, rgba(18, 33, 53,0.95), rgba(37, 71, 116,0.96)), url(/document-uploads/knitting.jpeg);
	box-shadow: 7px -5px 27px rgba(0,0,0,0.5) inset;
	top: 0px;
	right: 0px;
	z-index: -1;
}
#mobile h3 {
	background: transparent !important;
}
#mobile a {
	border-bottom: 0px;
	background: transparent !important;

}
#mobile ul ul a {
	background-color: rgba(22, 40, 65, 0.6) !important;
	border-bottom: 0px !important;
}

/* Mobile Button */
.mobile-btn {
	display:none;
	width:40px;
	height:35px;
	float:right;
	margin-top:10px;
	position:relative;
	-webkit-transition:all 500ms;
	z-index: 99999;
	margin: 15px;
}
.mobile-btn span {
	content:"";
	width:80%;
	height:3px;
	display:block;
	background-color:#fff;
	position:absolute;
	left:50%;
	margin-left:-40%;
	-webkit-transition:all 500ms;
}
.mobile-btn span:nth-child(1) {
	top:8px;
}
.mobile-btn span:nth-child(2) {
	top:16px;
}
.mobile-btn span:nth-child(3) {
	top:24px;
}

.mobile-btn.active {
	-webkit-transform:rotate3d(0, 0, 1, 180deg);
}
.mobile-btn.active span:nth-child(1) {
	-webkit-transform:rotate3d(0,0,1,45deg) translate(5.5px,5.5px);
}
.mobile-btn.active span:nth-child(2) {
	opacity:0;
}
.mobile-btn.active span:nth-child(3) {
	-webkit-transform:rotate3d(0,0,1,-45deg) translate(5.8px,-5.7px);
}

#mobile {
	position:fixed;
	overflow:auto;
	right:-250px;
	height:100%;
	width:250px;
	background-color:#fff;
	-webkit-transition:all 200ms;
}

#mobile .title {
	background-color:#333366;
	color:#fff;
	text-transform: uppercase;
	font-weight: 400;
	padding:12px 15px;
	font-size:18px;
}
#mobile a {
	color:#fff;
	display: block;
	padding:8px 15px;
	position: relative;
}
#mobile ul ul a {
	color:#fff;
	display: block;
	padding:8px 30px;
	padding-right: 40px !important;
	background-color: rgba(51, 51, 102, 0.8);
}
#mobile ul ul ul a {
	color:#fff;
	display: block;
	padding:8px 45px;
	background-color:rgba(51, 51, 102, 0.7);
}
#mobile ul ul ul ul a {
	color:#fff;
	display: block;
	padding:8px 60px;
	background-color:rgba(51, 51, 102, 0.7);
}
#mobile #main {
	background-color: #333366;
}

 #onmenu {
 	display: none !important;
	height: 20px !important; 
	width: 30px !important;
	padding-top: 12px !important;
}

@media (min-width: 836px) {
	#onmenu {
        display: block !important;
        width:40px;
        height:35px;
        float:right;
        margin-top:10px;
        position:relative;
        -webkit-transition:all 500ms;
    }
    #onmenu span {
        content:"";
        width:80%;
        height:3px;
        display:block;
        background-color:#fff;
        position:absolute;
        left:50%;
        margin-left:-40%;
        -webkit-transition:all 500ms;
    }
    #onmenu span:nth-child(1) {
        top:8px;
    }
    #onmenu span:nth-child(2) {
        top:16px;
    }
    #onmenu span:nth-child(3) {
        top:24px;
    }

    #onmenu.active {
        -webkit-transform:rotate3d(0, 0, 1, 180deg);
    }
    #onmenu.active span:nth-child(1) {
        -webkit-transform:rotate3d(0,0,1,45deg) translate(5.5px,5.5px);
    }
    #onmenu.active span:nth-child(2) {
        opacity:0;
    }
    #onemnu.active span:nth-child(3) {
        -webkit-transform:rotate3d(0,0,1,-45deg) translate(5.8px,-5.7px);
    }
}

/* ------------------------------------------------------------------------------------------
Global Style
------------------------------------------------------------------------------------------ */

/* Header
--------------------------------------------- */
header {
	transition: 0.3s;
	right: 0;
}
header.active {
	right: 250px;
}


/* Content
--------------------------------------------- */



/* Footer
--------------------------------------------- */




/* ------------------------------------------------------------------------------------------
Layout Specific styles 
------------------------------------------------------------------------------------------ */

/* Home
--------------------------------------------- */

/* Community Resources
---------------------------------------------*/
.community-resources-cta #donate-content-container h4 {
	font-size: 38px;

}
.community-resources-cta #button-container {
	margin-top: 0 !important;
}
@media(max-width: 991px) {
	.community-resources-cta #donate-content-container h4 {
		margin-bottom: 25px;
		text-align: center;
	}
}
@media(max-width: 767px) {
	.community-resources-cta #donate-content-container h4 {
		font-size: 20px;
		line-height: 1.2;
	}
}

/* Summer Events
--------------------------------------------- */

.summer-event-calendar {
	display: table;
	width: 100%;
	position: relative;
	margin-bottom:30px;
	box-shadow: 0px 0px 10px #aaa;
	min-width: 500px;
}
.summer-event-calendar thead {
	background-color: #61BD85;
	/*padding: 15px;*/
}
.summer-event-calendar thead th {
	color: #fff;
	/*padding: 5px 15px;*/
}
.summer-event-calendar th, .summer-event-calendar td {
	padding: 15px;
	min-width: 100px;
}

.summer-event-calender th {
}


/* Above And Beyond
--------------------------------------------- */

.control-wrap {
    max-width: 772px;
    margin: 0 auto;
    display: block;
    font-size: 14px;
}

.control-wrap a {
	width: 100%;
	text-align: center;
}

.bg-parallax {
	height: 800px;
	width: 100% !important;
	position: fixed;
	display: table;
}

footer.ilc-footer {

}

footer.ilc-footer .all-rights {
	text-align: left;
}

footer.ilc-footer .all-rights a {
	color: white;
}

footer.ilc-footer {
	padding: 20px 0;
	background: #424242;
	color: #888;
    position: relative;
}

@media (max-width: 992px) {
	footer.ilc-footer {
		text-align: center;
	}
	footer.ilc-footer .all-rights {
		margin-bottom: 20px;
		text-align: center;
	}
	footer.ilc-footer .address-contact {
		margin-top: 20px;
	}
}

.upload-btn {
	width: 170px;
	text-align: center;
	padding: 7px 0;
	margin-top: 20px;
	background: transparent;
	border: 2px solid #323232;
	color: #323232;
    font-family: 'Raleway', sans-serif;
	font-weight: 700;
	font-size: 14px;
}

.upload-btn:hover,
.upload-btn:focus {
	background: #323232;
}

.upload-btn input {
	position:absolute;
	z-index:2;
	top:0;
	left:0;
	filter: alpha(opacity=0);
	-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	opacity:0;
	background-color:transparent;
	color:transparent;
}

.upload-btn i {
	margin-right: 7px;
}

.bg-parallax {
    width: 100%;
    height: 800px;
    height: 100vh;
}

.modaloff {
    opacity: 0;
    z-index: -9;
    position: fixed;
    top: 50px;
    left: 50%;
    width: 500px;
    margin-left: -250px;
    background: white;
    padding: 20px;
    transition: all .25s ease-in-out;
   -moz-transition: all .25s ease-in-out;
   -webkit-transition: all .25s ease-in-out;
}

.modaloff.show {
	top: 100px;
    opacity: 1;
    z-index: 99;
    transition: all .25s ease-in-out;
   -moz-transition: all .25s ease-in-out;
   -webkit-transition: all .25s ease-in-out;
}

@media (max-width: 500px) {
	.modaloff {
		max-width: 100%;
		width: 100%;
		margin: 0;
		left: 0;
		height: 100vh;
	}
	.modaloff.show {
		top: 0;
	}
}

.modaloff h3 {
	color: #E75B36;
}

@media (max-width: 480px) {
	.modaloff h3 {
		font-size: 18px;
		letter-spacing: 1.3px;
	}
}

.modaloff input:focus {
	border-color: #E75B36;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(231, 91, 54, .6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(231, 91, 54, .6);
}

.spon-options-dd {
	width: 255px;
	padding: 10px 13px;
	border-radius: 0;
	border: 2px solid #323232;
	margin-top: 10px;
    -webkit-appearance: none;
        font-family: 'Raleway', sans-serif;
    font-weight: 700;
    cursor: pointer;
    outline: 0;
    font-size: 14px;
    letter-spacing: 1.3px;
	min-height: 50px;
}

.select-group {
	position: relative;
    width: 260px;
}
.select-group .fa {
	position: absolute;
	z-index: 99;
    top: 29px;
    right: 20px;
    font-size: 12px;
}

.spon-options-dd:hover,
.spon-options-dd:focus,
.spon-options-dd:active {
	background: #323232;
	color: white;
	border: 2px solid #323232;
}

.close-modal {
	width: 40px;
	height: 40px;
	padding: 7px 0;
	text-align: center;
	position: absolute;
	z-index: 999;
	top: 0;
	right: 0;
	background: #323232;
	color: white;
	cursor: pointer;
}

.sponsor-submit {
	width: 100%;
	margin-top: 20px;
	padding: 17px 0;
	font-size: 16px;
}

#upload-file-info {
	background: transparent;
	color: #323232;
}

.about-landing-wrap {
	padding: 60px 0;
	margin-top: 100%;
	margin-top: 100vh;
	position: relative;
	background: white;
}

p.landing {
	font-size: 50px !important;
}

@media (max-width: 1200px) {
	p.landing {
		font-size: 43px !important;
	}
	p.text {
		font-size: 30px !important;
	}
}

@media (max-width: 992px) {
	.control-wrap a {
		margin-bottom: 10px;
	}
}

@media (max-width: 480px) {
	p.landing {
		line-height: 48px !important;
	}
	p.text {
		margin-bottom: 20px;
	}
}

.spon-options-dd:hover,
 .spon-options-dd:focus,
  .spon-options-dd.focus,
   .spon-options-dd:active,
    .spon-options-dd.active,
     .open > .dropdown-toggle.spon-options-dd {
     	background: #323232;
     	border-color: #323232;
     	color: white;
     }


.darken {
	position: fixed;
	z-index: -9;
	opacity: 0;
	transition: all .25s ease;
   -moz-transition: all .25s ease;
   -webkit-transition: all .25s ease;
}
.darken.show {
	opacity: 1;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .75);
    z-index: 9;
    transition: all .25s ease;
   -moz-transition: all .25s ease;
   -webkit-transition: all .25s ease;
}

.about-landing-wrap.thank-you {
    position: fixed;
    bottom: 0;
    width: 100%;
}

.vert-center {
	display: table-cell;
	vertical-align: middle;
}

.bg-parallax img {
	margin: 0 auto;
	display: block;
	padding: 0 15px;
}

.bg-parallax.thank-you img {
	width: 100%;
    max-width: 930px;
    margin-top: -340px;
}

@media (max-height: 769px) {
	.bg-parallax.thank-you img {
		width: 100%;
	    max-width: 600px;
	    margin-top: -340px;
	}
}

@media (max-width: 480px) {
	.bg-parallax.thank-you img {
	    margin: 0 auto;
	    display: block;
	    padding: 0 15px;
	    width: 100%;
	    max-width: 930px;
	    margin-top: -220px;
	}
	.about-landing-wrap.thank-you {
		padding: 10px 0;
	    position: fixed;
	    bottom: 0;
	    width: 100%;
	}
}

.scroll-down {
	font-size: 19px;
	position: fixed;
	z-index: 8;
	bottom: 30px;
	left: 50%;
	margin-left: -25px;
	color: white;
	background: #E75B36;
	width: 50px;
	height: 50px;
	-webkit-border-radius: 32px;
	-moz-border-radius: 32px;
	border-radius: 32px;
	text-align: center;
	padding: 10px 0;
	cursor: pointer;
	opacity: .8;
	transition: all .75s ease;
   -moz-transition: all .75s ease;
   -webkit-transition: all .75s ease;
}

.scroll-down:hover,
.scroll-down:focus {
	opacity: 1;
	transition: all .75s ease;
   -moz-transition: all .75s ease;
   -webkit-transition: all .75s ease;
}

