/* General styles for all menus */
.cbp-spmenu {
    
    position: fixed;
}

/* Orientation-dependent styles for the content of the menu */
.cbp-spmenu-vertical {
    width: 270px;
    height: 100%;
    top: 0;
    overflow-y: auto;
    transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    
}


.cbp-spmenu-horizontal {
    width: 100%;
    height:64px;
    left: 0;
    overflow: hidden;
    top: 0;

    
}



/* Vertical menu that slides from the left or right */
.cbp-spmenu-left {
    left: -270px;
}

.cbp-spmenu-right {
    right: -270px;
}

.cbp-spmenu-left.menu-open {
    left: 0px;
}

.cbp-spmenu-right.menu-open {
    right: 0px;
}

/* Horizontal menu that slides from the top or bottom */

.cbp-spmenu-top {
    top: -64px;

}

.cbp-spmenu-bottom {
    bottom: -100px;
}


.cbp-spmenu-top.menu-open {
    top: 0px;

}

.cbp-spmenu-bottom.menu-open {
    bottom: 0px;
}

/* Push classes applied to the body */
.push-body {
    overflow-x: hidden;
    position: relative;
    left: 0;
    
}

.push-body-toright {
    left: 270px;
    transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
}

.push-body-toleft {
    left: -270px;
    
}
.push-body-totop {
    top: 64px;
    

}

.push-body, .cbp-spmenu-push{
    transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
}


