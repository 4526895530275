/* 
 * 	Core Owl Carousel CSS File
 *	v1.3.3
 */

/* clearfix */
.owl-carousel .owl-wrapper:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}
/* display none until init */
.owl-carousel {
    display: none;
    position: relative;
    width: 100%;
    -ms-touch-action: pan-y;
}

    .owl-carousel .owl-wrapper {
        display: none;
        position: relative;
        -webkit-transform: translate3d(0px, 0px, 0px);
    }

    .owl-carousel .owl-wrapper-outer {
        overflow: hidden;
        position: relative;
        width: 100%;
    }

        .owl-carousel .owl-wrapper-outer.autoHeight {
            -webkit-transition: height 500ms ease-in-out;
            -moz-transition: height 500ms ease-in-out;
            -ms-transition: height 500ms ease-in-out;
            -o-transition: height 500ms ease-in-out;
            transition: height 500ms ease-in-out;
        }

    .owl-carousel .owl-item {
        float: left;
    }

.owl-controls .owl-page,
.owl-controls .owl-buttons div {
    cursor:;
}

.owl-controls {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* mouse grab icon */
.grabbing {
    cursor: url(grabbing.png) 8 8, move;
}

/* fix */
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0,0,0);
    -moz-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
}






/*
* 	Owl Carousel Owl Demo Theme 
*	v1.3.3
*/

.owl-carousel .owl-controls {
    text-align: center;
}

    /* Styling Next and Prev buttons */

    .owl-carousel .owl-controls .owl-buttons div {
        cursor:pointer;
        display: inline-block;
        
        
    }
    /* Clickable class fix problem with hover on touch devices */
    /* Use it for non-touch hover action */
    .owl-carousel .owl-controls.clickable .owl-buttons div:hover {
        filter: Alpha(Opacity=100); /*IE7 fix*/
        opacity: 1;
        text-decoration: none;
    }

    /* Styling Pagination*/

    .owl-carousel .owl-controls .owl-page {
        display: inline-block;
        zoom: 1;
        *display: inline; /*IE7 life-saver */
    }

        .owl-carousel .owl-controls .owl-page span {
            display: block;
            width: 8px;
            height: 8px;
            margin: 0px 5px;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            border-radius: 20px;
            background: #323232;
            border: 2px solid;
            border-color: none;
            transition: all 0.3s ease 0s;
            -o-transition: all 0.3s ease 0s;
            -moz-transition: all 0.3s ease 0s;
            -webkit-transition: all 0.3s ease 0s;
            cursor: pointer;
        }
        /*.owl-carousel .owl-controls.clickable .owl-page:hover span,*/
        .owl-carousel .owl-controls .owl-page.active span {
            background: rgba(0,0,0,0);
            border: 2px solid #323232;
            transform: scale(1.5);
            -webkit-transform: scale(1.5);
            -moz-transform: scale(1.5);
            -ms-transform: scale(1.5);
            -o-transform: scale(1.5);
            
        }

        /* If PaginationNumbers is true */

        .owl-carousel .owl-controls .owl-page span.owl-numbers {
            height: auto;
            width: auto;
            color: #FFF;
            padding: 2px 10px;
            font-size: 12px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            border-radius: 30px;
        }

/* preloading images */
.owl-item.loading {
    min-height: 150px;
    background: url(AjaxLoader.gif) no-repeat center center;
}
